:root {
    --navbar-height: 70px;
    --margin-1: 0.25rem;
    --margin-2: 0.5rem;
    --margin-3: 1rem;
    --margin-4: 1.5rem;
    --margin-5: 2rem;
    --padding-1: 0.25rem;
    --padding-2: 0.5rem;
    --padding-3: 1rem;
    --padding-4: 1.5rem;
    --padding-5: 2rem;
    --game-card-width: 180px;
    --game-card-height: 170px;
    --border-radius-5: 5px
}

@media (min-width:768px) {
    :root {
        --navbar-height: 70px;
        --navbar-z-index: 999;
        --float-menu-z-index: 998;
        --game-card-width: 200px;
    }
}