@tailwind base;
@tailwind components;
@tailwind utilities;

@import './colors';
@import './font';

:root {
    --vh: 100vh;
}

html,
body {
    padding: 0;
    margin: 0;
    outline: 0;

    * {
        font-family:"Scto Grotesk A", "Gilroy", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
            Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    }

    height: fit-content;
    min-height: 100vh;
    background-color:var(--root-bg);
    color: var(--text-color);
}


html::-webkit-scrollbar,
body::-webkit-scrollbar {
    width: 3px;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.8);
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.8);
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
    background-color: var(--primary);
}

#__next {
    display: block;
    max-width: 100vw;
    overflow-x: hidden;
}

input {
    color-scheme: light !important;
}

.ql-align-center {
    text-align: center;
}

.ql-align-left {
    text-align: left;
}

.ql-align-right {
    text-align: right;
}

.ql-align-justify {
    text-align: justify;
}

a{
    color: var(--primary);
}
