@import './variables';
@import './ReactToastify.scss';
@import './animation.scss';
@import './groupSelect.scss';
@import './quill.snow.css';

html, body {
  height: 100%;
  margin: 0;
  min-height: 100vh;
  height: -webkit-fill-available;
}

body{
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
* {
  box-sizing: border-box;
}

a {
  color: var(--link-color);
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

a:hover {
  color: var(--link-color-hover);
  font-weight: 600;
}

p {
  margin: 0;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input {
  color-scheme: dark;
}


strong,
b {
  font-weight: 800;
}

*:focus,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.bonus-name-gradient {
  font-weight: 700;
  letter-spacing: 0.05em;
  text-align: center;
  background: linear-gradient(160deg, #E93C86 35.62%, #FD6148 75.75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}


.bonus-percent-gradient {
  font-weight: 700;
  font-size: 46px;
  line-height: 77px;
  letter-spacing: 0.05em;
  text-align: center;
  background: linear-gradient(111.09deg, #FFF561 5.62%, #FFA31E 75.75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.gold-typography {
  background: linear-gradient(111.09deg, #FFF561 5.62%, #FFA31E 75.75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: -7px 5px 18px rgba(255, 163, 30, 0.33);
}

.typography-row {
  width: 100%;
  background: linear-gradient(180deg, #51576A, transparent);
  padding: 2px;
  border-radius: 0.5rem;

  .typography-row-content {
    width: 100%;
    background: var(--primary);
    border-radius: 0.5rem;
    height: 100%;
    padding: 0.5rem;
  }
}


.scroll::-webkit-scrollbar {
  width: 3px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.8);
          box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.8);
}

.scroll::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}

.tox .tox-dialog-wrap {
  z-index: 999999999999999999999999999;
}

.cta-primary,
.cta-secondary {
  width: fit-content;
  background: var(--primary) !important;
  color: #fff !important;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 2rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, filter 0.5s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  text-decoration: none !important;
  * {
    text-decoration: none !important;
  }
}

.cta-secondary {
  background: #ffeb89 !important;
  color: var(--primary) !important;
}
input::placeholder {
  color: var(--text-color-dark);
  opacity: 0.5;
}

@media (max-width:540px) {
  .zendesk-chat-widget, #launcher {
    display: none !important;
  }
}
