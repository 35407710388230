.cookieNotification {
    width: 640px;
    max-width: calc(100vw - 2rem);
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.75);
    z-index: 9999999999;
    padding: 2rem;
    border-radius: 0.6669rem;
    background-color: var(--root-bg);


    @supports (width: 100dvw) {
        max-width: calc(100dvw - 2rem);
    }
}

.wrapper {
    gap: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    * {
        a {
            color: var(--primary) !important;
        }
    }
}