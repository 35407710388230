@font-face {
    font-family: 'Scto Grotesk A';
    src: url('/assets/fonts/black.otf') format('opentype'),
         url('/assets/fonts/black/font.woff') format('woff'),
         url('/assets/fonts/black/font.eot') format('embedded-opentype');
    font-weight: 900; // Black
    font-style: normal;
}

@font-face {
    font-family: 'Scto Grotesk A';
    src: url('/assets/fonts/bold.otf') format('opentype'),
         url('/assets/fonts/bold/font.woff') format('woff'),
         url('/assets/fonts/bold/font.eot') format('embedded-opentype');
    font-weight: bold; // Bold
    font-style: normal;
}

@font-face {
    font-family: 'Scto Grotesk A';
    src: url('/assets/fonts/regular.otf') format('opentype'),
         url('/assets/fonts/regular/font.woff') format('woff'),
         url('/assets/fonts/regular/font.eot') format('embedded-opentype');
    font-weight: normal; // Regular
    font-style: normal;
}