.wrapper {
    background: var(--primary);
    color: #fff;

    section {
        width: 100%;
    }
}

@media (max-width:768px) {
    .wrapper[data-path="/"] {
        padding-bottom: 2rem;
        margin-bottom: -2rem;
    }
}