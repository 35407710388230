@keyframes animationSideAnchor {
    0% {
        max-width: 0;
    }

    100% {
        max-width: 100vw;
    }
}

.dialog-wrapper {
    width: 100vw;
    height: var(--vh, 100vh);
    background-color: rgba(0, 0, 0, .15);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    border: 0;
    margin: 0;
    outline: 0;

    .dialog {
        max-width: 100vw;
        width: fit-content;
        height: fit-content;
        max-height: 100%;
        overflow-y: auto;
        position: relative;
        overflow-x: hidden;
        border-radius: 1rem;


        .close-section {
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            z-index: 999;
            padding: 1rem;

            svg {
                path:nth-child(2) {
                    transition: all 300ms ease-in-out;
                    transform-origin: center center;
                }
            }

            svg:hover {
                path:nth-child(2) {
                    transform: scale(1.1);
                }
            }
        }
    }

    .dialog::-webkit-scrollbar {
        width: 3px;
    }

    .dialog::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.8);
    }

    .dialog::-webkit-scrollbar-thumb {
        background-color: var(--secondary);
    }

}

.dialog-wrapper[data-height='UNDER-NAVBAR'] {
    top: var(--navbar-height);
    height: calc((var(--vh, 100vh)) - var(--navbar-height));
}
.dialog-wrapper[data-hidden='true'] {
    z-index: 1 !important;
    opacity: 0.5;
}

.dialog-wrapper[data-anchor='center'] {
    .dialog {
        border-radius: 6pt;
    }
}

.dialog-wrapper[data-anchor='position'] {
    width: 100dvw;
    height: 100dvh;
    z-index: 9999;
    background: transparent;
    backdrop-filter: unset;

    .dialog {
        position: fixed;
        transform: translateX(-100%);
    }
}


.dialog-wrapper[data-anchor='left'] {
    justify-content: flex-start;

    .dialog {
        transition: max-width 300ms ease-in-out;
        animation-name: animationSideAnchor;
        animation-duration: 300ms;
        animation-timing-function: ease-in-out;
        animation-delay: 0;
        animation-iteration-count: 1;
    }
}

.dialog-wrapper[data-anchor='right'] {
    justify-content: flex-end;

    .dialog {
        transition: max-width 300ms ease-in-out;
        animation-name: animationSideAnchor;
        animation-duration: 300ms;
        animation-timing-function: ease-in-out;
        animation-delay: 0;
        animation-iteration-count: 1;
    }
}
