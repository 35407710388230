:root {
    --primary: #4A26FF;
    --primary-900: rgba(74, 38, 255, 0.9);
    --primary-800: rgba(74, 38, 255, 0.8);
    --primary-700: rgba(74, 38, 255, 0.7);
    --primary-600: rgba(74, 38, 255, 0.6);
    --primary-500: rgba(74, 38, 255, 0.5);
    --primary-400: rgba(74, 38, 255, 0.4);
    --primary-300: rgba(74, 38, 255, 0.3);
    --primary-200: rgba(74, 38, 255, 0.2);
    --primary-100: rgba(74, 38, 255, 0.1);
    --secondary: rgb(255, 235, 137, 1);
    --secondary-900: rgba(255, 235, 137, 0.9);
    --secondary-800: rgba(255, 235, 137, 0.8);
    --secondary-700: rgba(255, 235, 137, 0.7);
    --secondary-600: rgba(255, 235, 137, 0.6);
    --secondary-500: rgba(255, 235, 137, 0.5);
    --secondary-400: rgba(255, 235, 137, 0.4);
    --secondary-300: rgba(255, 235, 137, 0.3);
    --secondary-200: rgba(255, 235, 137, 0.2);
    --secondary-100: rgba(255, 235, 137, 0.1);
    --tertiary: #474851;
    --tertiary-900: rgba(71, 72, 81, 0.9);
    --tertiary-800: rgba(71, 72, 81, 0.8);
    --tertiary-700: rgba(71, 72, 81, 0.7);
    --tertiary-600: rgba(71, 72, 81, 0.6);
    --tertiary-500: rgba(71, 72, 81, 0.5);
    --tertiary-400: rgba(71, 72, 81, 0.4);
    --tertiary-300: rgba(71, 72, 81, 0.3);
    --tertiary-200: rgba(71, 72, 81, 0.2);
    --tertiary-100: rgba(71, 72, 81, 0.1);

    --gray: #A4A4A4;
    --disabled: #CBD3DB;
    --gradient-primary: #4A26FF;
    --gradient-secondary: #4A26FF;
    --sports-primary: #185DC6;
    --sports-gradient-primary: linear-gradient(228.29deg, #0066FF 0%, #00D1FF 100%);
    --success: #44CC00;
    --error: #D60031;
    --danger: #D60031;
    --warning: #FFF561;
    --warn: #FFF561;
    --info: #185DC6;
    --currency: #FFE455;

    --text-color: #4A26FF;
    --text: #4A26FF;
    --text-color-dark: #14171F;
    --text-color-light: #fff;
    --root-bg: #fff;
    --navbar-bg: #FAFAFA;
    --card-bg: #F8F8F8;
    --input-bg: #EFEEEE;
    --input-border: #FCF8F7;
    --input-color: #4A26FF;
    --editor-tool-bar-bg: #fff;
    --editor-bg: #fff;
    --option-hover-bg: #FCF8F7;
    --side-menu-bg: #fff;
    --section-bg: #fff;
    --modal-bg: #fff;
    --link-color: #000;
    --link-color-hover: #4A26FF;
    --toast-container-border: #836DF3;

    --yellow-hover-bg: #FFF3B5;
    --yellow-active-bg: #FFF7D0; // rgba(255, 247, 208, 1)
    --outline-primary-btn-hover-bg: rgba(74, 38, 255, 0.1);
    --outline-primary-btn-active-bg: var(--primary);
    --outline-white-btn-hover-bg: rgba(255, 255, 255, 0.1);
    --outline-white-btn-active-bg: var(--root-bg);
    --x-dark-active-bg: rgba(237, 234, 255, 1); // #EDEAFF
}
