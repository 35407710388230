.button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    color: #fff;
    border-radius: 0.5rem;
    transition: color 0.05s ease-in-out, filter 0.5s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button[data-state='disabled'],
.button:disabled {
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    cursor: default;
}

.button[hidden] {
    display: none;
}

.button[data-block='true'] {
    width: 100%;
}

.button[data-style-button='rounded'] {
    border-radius: 16pt;
}

// Colors

.button[data-color='primary'] {
    background-color: var(--primary);
    border: solid 2px;
    border-color: var(--primary);
}

.button[data-color='disable'] {
    background-color: #efeeee;
    border: solid 2px;
    border-color: #efeeee;
    color: var(--primary)
}

.button[data-color='primary']:hover {
    filter: brightness(1.2);
}

.button[data-color='yellow'] {
    background-color: var(--secondary);
    border: none;
    color: var(--primary);
    text-transform: uppercase;

    &:hover,
    &:focus {
        background: var(--yellow-hover-bg);
    }

    &:active {
        background: var(--yellow-active-bg);
    }
}

.button[data-color='white'] {
    background-color: #FFF;
    border: solid 2px;
    border-color: #FFF;
    color: var(--primary);
}

.button[data-color='white']:hover {
    filter: brightness(1.2);
}

.button[data-color='outline-white'] {
    background-color: transparent;
    border: solid 1.5px;
    border-color: #FFF;
    color: #FFF;
    font-weight: 700;

    &:hover,
    &:focus {
        background-color: var(--outline-white-btn-hover-bg);
    }

    &:active {
        background-color: var(--outline-white-btn-active-bg);
        color: var(--primary);
    }
}

.button[data-color='outline-primary'] {
    background-color: transparent;
    border: solid 1.5px;
    border-color: var(--primary);
    color: var(--primary);
    padding: 0.35rem 0.75rem;

    &:hover,
    &:focus {
        background-color: var(--outline-primary-btn-hover-bg);
    }

    &:active {
        background-color: var(--outline-primary-btn-active-bg);
        color: #FFF;
    }
}

// Margin
.button[data-margin*='m-1'] {
    margin: var(--margin-1, 0.25rem);
}

.button[data-margin*='m-2'] {
    margin: var(--margin-2, 0.5rem);
}

.button[data-margin*='m-3'] {
    margin: var(--margin-3, 1rem);
}

.button[data-margin*='m-4'] {
    margin: var(--margin-4, 1.5rem);
}

.button[data-margin*='m-5'] {
    margin: var(--margin-5, 2rem);
}

.button[data-margin*='mt-1'] {
    margin-top: var(--margin-1, 0.25rem);
}

.button[data-margin*='mt-2'] {
    margin-top: var(--margin-2, 0.5rem);
}

.button[data-margin*='mt-3'] {
    margin-top: var(--margin-3, 1rem);
}

.button[data-margin*='mt-4'] {
    margin-top: var(--margin-4, 1.5rem);
}

.button[data-margin*='mt-5'] {
    margin-top: var(--margin-5, 2rem);
}

.button[data-margin*='mb-1'] {
    margin-bottom: var(--margin-1, 0.25rem);
}

.button[data-margin*='mb-2'] {
    margin-bottom: var(--margin-2, 0.5rem);
}

.button[data-margin*='mb-3'] {
    margin-bottom: var(--margin-3, 1rem);
}

.button[data-margin*='mb-4'] {
    margin-bottom: var(--margin-4, 1.5rem);
}

.button[data-margin*='mb-5'] {
    margin-bottom: var(--margin-5, 2rem);
}

.button[data-margin*='ms-1'] {
    margin-left: var(--margin-1, 0.25rem);
}

.button[data-margin*='ms-2'] {
    margin-left: var(--margin-2, 0.5rem);
}

.button[data-margin*='ms-3'] {
    margin-left: var(--margin-3, 1rem);
}

.button[data-margin*='ms-4'] {
    margin-left: var(--margin-4, 1.5rem);
}

.button[data-margin*='ms-5'] {
    margin-left: var(--margin-5, 2rem);
}

.button[data-margin*='me-1'] {
    margin-right: var(--margin-1, 0.25rem);
}

.button[data-margin*='me-2'] {
    margin-right: var(--margin-2, 0.5rem);
}

.button[data-margin*='me-3'] {
    margin-right: var(--margin-3, 1rem);
}

.button[data-margin*='me-4'] {
    margin-right: var(--margin-4, 1.5rem);
}

.button[data-margin*='me-5'] {
    margin-right: var(--margin-5, 2rem);
}

.xsm {
    height: 30px;
    line-height: 30px;
}

.sm {
    height: 35px;
    line-height: 35px;
}

.md {
    height: 50px;
    line-height: 50px;
}

.lg {
    height: 90px;
    line-height: 90px;
}

.xl {
    height: 137px;
    line-height: 137px;
}

@media (min-width:768px) {
    .button[data-margin*='m-md-1'] {
        margin: var(--margin-1, 0.25rem);
    }

    .button[data-margin*='m-md-2'] {
        margin: var(--margin-2, 0.5rem);
    }

    .button[data-margin*='m-md-3'] {
        margin: var(--margin-3, 1rem);
    }

    .button[data-margin*='m-md-4'] {
        margin: var(--margin-4, 1.5rem);
    }

    .button[data-margin*='m-md-5'] {
        margin: var(--margin-5, 2rem);
    }

    .button[data-margin*='mt-md-1'] {
        margin-top: var(--margin-1, 0.25rem);
    }

    .button[data-margin*='mt-md-2'] {
        margin-top: var(--margin-2, 0.5rem);
    }

    .button[data-margin*='mt-md-3'] {
        margin-top: var(--margin-3, 1rem);
    }

    .button[data-margin*='mt-md-4'] {
        margin-top: var(--margin-4, 1.5rem);
    }

    .button[data-margin*='mt-md-5'] {
        margin-top: var(--margin-5, 2rem);
    }

    .button[data-margin*='mb-md-1'] {
        margin-bottom: var(--margin-1, 0.25rem);
    }

    .button[data-margin*='mb-md-2'] {
        margin-bottom: var(--margin-2, 0.5rem);
    }

    .button[data-margin*='mb-md-3'] {
        margin-bottom: var(--margin-3, 1rem);
    }

    .button[data-margin*='mb-md-4'] {
        margin-bottom: var(--margin-4, 1.5rem);
    }

    .button[data-margin*='mb-md-5'] {
        margin-bottom: var(--margin-5, 2rem);
    }

    .button[data-margin*='ms-md-1'] {
        margin-left: var(--margin-1, 0.25rem);
    }

    .button[data-margin*='ms-md-2'] {
        margin-left: var(--margin-2, 0.5rem);
    }

    .button[data-margin*='ms-md-3'] {
        margin-left: var(--margin-3, 1rem);
    }

    .button[data-margin*='ms-md-4'] {
        margin-left: var(--margin-4, 1.5rem);
    }

    .button[data-margin*='ms-md-5'] {
        margin-left: var(--margin-5, 2rem);
    }

    .button[data-margin*='me-md-1'] {
        margin-right: var(--margin-1, 0.25rem);
    }

    .button[data-margin*='me-md-2'] {
        margin-right: var(--margin-2, 0.5rem);
    }

    .button[data-margin*='me-md-3'] {
        margin-right: var(--margin-3, 1rem);
    }

    .button[data-margin*='me-md-4'] {
        margin-right: var(--margin-4, 1.5rem);
    }

    .button[data-margin*='me-md-5'] {
        margin-right: var(--margin-5, 2rem);
    }
}

@media (min-width:992px) {
    .button[data-margin*='m-lg-1'] {
        margin: var(--margin-1, 0.25rem);
    }

    .button[data-margin*='m-lg-2'] {
        margin: var(--margin-2, 0.5rem);
    }

    .button[data-margin*='m-lg-3'] {
        margin: var(--margin-3, 1rem);
    }

    .button[data-margin*='m-lg-4'] {
        margin: var(--margin-4, 1.5rem);
    }

    .button[data-margin*='m-lg-5'] {
        margin: var(--margin-5, 2rem);
    }

    .button[data-margin*='mt-lg-1'] {
        margin-top: var(--margin-1, 0.25rem);
    }

    .button[data-margin*='mt-lg-2'] {
        margin-top: var(--margin-2, 0.5rem);
    }

    .button[data-margin*='mt-lg-3'] {
        margin-top: var(--margin-3, 1rem);
    }

    .button[data-margin*='mt-lg-4'] {
        margin-top: var(--margin-4, 1.5rem);
    }

    .button[data-margin*='mt-lg-5'] {
        margin-top: var(--margin-5, 2rem);
    }

    .button[data-margin*='mb-lg-1'] {
        margin-bottom: var(--margin-1, 0.25rem);
    }

    .button[data-margin*='mb-lg-2'] {
        margin-bottom: var(--margin-2, 0.5rem);
    }

    .button[data-margin*='mb-lg-3'] {
        margin-bottom: var(--margin-3, 1rem);
    }

    .button[data-margin*='mb-lg-4'] {
        margin-bottom: var(--margin-4, 1.5rem);
    }

    .button[data-margin*='mb-lg-5'] {
        margin-bottom: var(--margin-5, 2rem);
    }

    .button[data-margin*='ms-lg-1'] {
        margin-left: var(--margin-1, 0.25rem);
    }

    .button[data-margin*='ms-lg-2'] {
        margin-left: var(--margin-2, 0.5rem);
    }

    .button[data-margin*='ms-lg-3'] {
        margin-left: var(--margin-3, 1rem);
    }

    .button[data-margin*='ms-lg-4'] {
        margin-left: var(--margin-4, 1.5rem);
    }

    .button[data-margin*='ms-lg-5'] {
        margin-left: var(--margin-5, 2rem);
    }

    .button[data-margin*='me-lg-1'] {
        margin-right: var(--margin-1, 0.25rem);
    }

    .button[data-margin*='me-lg-2'] {
        margin-right: var(--margin-2, 0.5rem);
    }

    .button[data-margin*='me-lg-3'] {
        margin-right: var(--margin-3, 1rem);
    }

    .button[data-margin*='me-lg-4'] {
        margin-right: var(--margin-4, 1.5rem);
    }

    .button[data-margin*='me-lg-5'] {
        margin-right: var(--margin-5, 2rem);
    }
}
