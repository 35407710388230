.footer{
    background: var(--primary);
    width: 100%;
    min-height: 27.5rem;
    height: fit-content;
    color: #fff;
    border-radius: 1.5rem 1.5rem 0 0;
    * a{
        color: #fff;
    }
}
.contant{
    padding: 1.0rem;
    @media (min-width:768px) {
        padding-left: 4.25rem;
        padding-right: 4.25rem;
        padding-top: 4.5rem;
    }

    @media (max-width:768px) {
        padding:3rem;
    }
}

.footer[data-remove-radius='true']{
    border-radius: 0;
}

.certificateContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem 0 1rem;
    gap: 1rem;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

.linksContacts {
    flex-direction: column;

    @media (max-width:768px) {
        flex-direction: row;
    }
}

.socialMedia,
.linksContacts,
.linksStandards {
    @media (max-width:768px) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        padding-bottom: 2rem;
    }
}